import mapboxgl, {
  Map as mapboxGlMap,
  Marker as mapboxGlMarker,
} from 'mapbox-gl'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import 'mapbox-gl/dist/mapbox-gl.css'
import { MAPBOX_STYLE } from 'lib/constants'
import { useIntersectionObserver } from 'lib/hooks/useIntersectionObserver'
import { addAlphaToColor } from 'lib/style'

function calculateOffset(
  center: [number, number],
  offsetXLeft: number,
  map: React.MutableRefObject<mapboxgl.Map | undefined>
): [number, number] {
  if (!map.current) return [0, 0]

  const point = map.current.project(center)
  point.x -= offsetXLeft
  return map.current.unproject(point).toArray() as [number, number]
}

function adjustMapCenter(
  center: [number, number],
  map: React.MutableRefObject<mapboxgl.Map | undefined>,
  offsetXLeft: number
): void {
  if (!map.current) return

  if (window.innerWidth >= 1024) {
    const offsetCenter = calculateOffset(center, offsetXLeft, map)
    map.current.setCenter(offsetCenter)
  } else {
    map.current.setCenter(center)
  }
}

type Props = {
  initialPosition?: { latitude?: number; longitude?: number; zoom?: number }
  clinic: {
    id: string
    latitude: number
    longitude: number
  }
}

export const ClinicMap = ({
  initialPosition,
  clinic,
  ...props
}: Props): JSX.Element => {
  const offsetXLeft = -200

  const mapRef = useRef<mapboxgl.Map>()
  const mapContainerRef = useRef(null)

  const [isIntersecting, setIsIntersecting] = useState(false)

  useIntersectionObserver(mapContainerRef, {
    onIntersection: (isIntersecting) => {
      if (isIntersecting) {
        setIsIntersecting(true)
      }
    },
  })

  const clinicData: GeoJSON.Feature<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > = useMemo(() => {
    return {
      type: 'Feature',
      id: clinic.id,
      properties: {
        id: clinic.id,
        name: clinic.id,
      },
      geometry: {
        type: 'Point',
        coordinates: [clinic.longitude, clinic.latitude, 0.0],
      },
    }
  }, [clinic])

  // This starts and destroys the mapbox instance, we use ref to optimize the DOM responsiveness
  useEffect(() => {
    if (!isIntersecting) return
    const center: mapboxgl.LngLatLike =
      initialPosition?.latitude && initialPosition?.longitude
        ? [initialPosition.longitude, initialPosition.latitude]
        : [15.2551, 54.526] // center of europe
    const zoom = initialPosition?.zoom || 3

    mapRef.current = new mapboxGlMap({
      accessToken: process.env.NEXT_PUBLIC_MAPBOXGL_TOKEN,
      container: mapContainerRef.current || '',
      style: MAPBOX_STYLE,
      center,
      zoom,
      scrollZoom: true,
      attributionControl: false, // Disable the default attribution control
    })

    const offsetCenter = calculateOffset(center, offsetXLeft, mapRef)
    mapRef.current.setCenter(offsetCenter)

    // Loads the map configs
    mapRef.current?.on('load', () => {
      mapRef?.current?.resize()

      // Add clinic
      mapRef.current?.addSource('clinic', {
        type: 'geojson',
        data: clinicData,
        cluster: true,
        clusterMaxZoom: 8, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      })

      adjustMapCenter(center, mapRef, offsetXLeft)
    })

    window.addEventListener('resize', function () {
      adjustMapCenter(center, mapRef, offsetXLeft)
    })

    return () => {
      mapRef.current?.remove()
    }
  }, [clinicData, isIntersecting])

  useEffect(
    function addMarkers() {
      if (!isIntersecting) return
      const els: HTMLDivElement[] = []
      const markers: mapboxGlMarker[] = []

      // Add selected location to map
      if (clinic && mapRef.current) {
        const el = document.createElement('div')
        el.className = 'marker location'

        if (clinic) {
          els.push(el)

          const marker = new mapboxGlMarker(el)
            .setLngLat([clinic.longitude, clinic.latitude])
            .addTo(mapRef.current)
          markers.push(marker)

          el.className += ' selected'
        }
      }

      return () => {
        markers.forEach((marker) => {
          marker.remove()
        })
      }
    },
    [clinic, clinicData, isIntersecting]
  )

  return (
    <Container {...props}>
      <MapContainer ref={mapContainerRef} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 540px;

  .mapboxgl-map {
    height: 100%;
    border-radius: 1.25rem;
  }
`

const pulse = (color: string) => keyframes`
from {
  box-shadow: 0 0 0px 4px ${addAlphaToColor(color, 100)};
}
to {
  box-shadow: 0 0 0px 10px ${addAlphaToColor(color, 50)};
}
`

const MapContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .marker {
    width: 1rem;
    height: 1rem;
    border: 2px solid white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    cursor: pointer;
    z-index: 998;

    background-color: #b2447b;
  }

  .location {
    width: 0.75rem;
    height: 0.75rem;
    z-index: 998;
    animation: ${() => pulse('#b2447b')} 1s ease infinite alternate;
  }

  .selected {
    z-index: 999;
    animation: ${() => pulse('#b2447b')} 1s ease infinite alternate;
  }
`
