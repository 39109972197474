import { StoryblokStory } from 'storyblok-generate-ts'
import styled from 'styled-components'

import { Button, Tag } from 'components/ui'
import { Container } from 'components/ui/deprecated/Layout'
import { Text } from 'components/ui/deprecated/Text'
import {
  ClinicLocationStoryblok,
  WhereToFindUsStoryblok,
} from 'lib/storyblok/types'
import { textByLine } from 'lib/utils/content'

import { ClinicMap } from '../deprecated/ClinicLocator/ClinicMap'
import { Editable } from '../deprecated/Editable'

type Props = {
  block: WhereToFindUsStoryblok
}

export const WhereToFindUs = ({ block, ...props }: Props): JSX.Element => {
  const {
    clinic,
    tag_text,
    title,
    description,
    informations,
    directions_button_label,
  } = block

  const typedClinic = clinic as StoryblokStory<ClinicLocationStoryblok>

  const coordinates = typedClinic.content?.coordinates.split(',')

  return (
    <SectionContainer>
      <MapContainer {...props}>
        {typeof clinic !== 'string' && coordinates && (
          <ClinicMap
            clinic={{
              id: typedClinic.uuid,
              latitude: +coordinates[0],
              longitude: +coordinates[1],
            }}
            initialPosition={{
              zoom: 10,
              latitude: coordinates[0] ? +coordinates[0] : undefined,
              longitude: coordinates[1] ? +coordinates[1] : undefined,
            }}
          />
        )}

        <ClinicInformationCard>
          <Editable block={block}>
            <ClinicInformationCardHeader>
              {tag_text && <Tag>{tag_text}</Tag>}

              {title && (
                <Text as="p" variant="title/large">
                  {title}
                </Text>
              )}

              {description && (
                <Text as="p" variant="twenty">
                  {description}
                </Text>
              )}
            </ClinicInformationCardHeader>

            <ClinicInformationCardContent>
              {informations &&
                informations.map((information) => {
                  const { _uid, label, text } = information
                  return (
                    <Editable block={information} key={_uid}>
                      <ClinicInformationCardContentItem>
                        {label && (
                          <Text
                            as="p"
                            variant="eighteen/regular"
                            className="opacity-60"
                          >
                            {label}
                          </Text>
                        )}

                        {text &&
                          textByLine(text, (part) => {
                            return (
                              <Text as="p" variant="eighteen">
                                {part}
                              </Text>
                            )
                          })}
                      </ClinicInformationCardContentItem>
                    </Editable>
                  )
                })}
            </ClinicInformationCardContent>

            {typedClinic.content.address && (
              <div className="mt-5 w-full">
                <Button
                  icon="external"
                  iconPosition="right"
                  as="a"
                  variant="outline"
                  href={`https://maps.google.com?daddr=${encodeURIComponent(
                    typedClinic.content.address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {directions_button_label}
                </Button>
              </div>
            )}
          </Editable>
        </ClinicInformationCard>
      </MapContainer>
    </SectionContainer>
  )
}

const SectionContainer = styled(Container)`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  padding-left: 1.25rem;
  padding-right: 1.25rem;

  ${({ theme }) => theme.media.lg} {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;

    padding-left: 5rem;
    padding-right: 5rem;
  }
`

const MapContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column-reverse;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`

const ClinicInformationCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  ${({ theme }) => theme.media.lg} {
    background-color: ${({ theme }) => theme.colors.background.default};
    margin-bottom: 0rem;

    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;

    max-width: 442px;

    padding: 2rem;
    border-radius: 1.25rem;
  }
`

const ClinicInformationCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ClinicInformationCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-top: 2rem;
`

const ClinicInformationCardContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
